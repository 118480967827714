import * as React from "react"
import ReactMarkdown from "react-markdown"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Social from "../components/social"
import Header from "../components/header"
import Footer from "../components/footer"
import axios from "axios"

import { graphql } from "gatsby"
import { useEffect } from "react"
import { isBrowser } from "../utils/isBrowser"
import { scrollToSection } from "../utils/scrollToSection"

const ContactPage = props => {
  const nameField = React.useRef(null)
  const emailField = React.useRef(null)
  const phoneField = React.useRef(null)
  const descriptionField = React.useRef(null)
  const consentField = React.useRef(null)
  const [formSent, setFormSent] = React.useState(false)
  const [showError, setShowError] = React.useState(false)

  const handleSubmit = event => {
    event.preventDefault()
    setFormSent(false)
    setShowError(false)

    let form = {
      Contactform: props.data.strapiContact.slug,
	  Language: props.data.strapiContact.locale,
      Name: nameField.current.value,
      Email: emailField.current.value,
      "Phone number": phoneField.current.value,
      Message: descriptionField.current.value,
      Consent: consentField.current.value,
    }
    let token = "token"

    axios
      .post("https://cms.netconsultiv.com/api/ezforms/submit", {
        token,
        formData: form,
      })
      .then(res => {
        setFormSent(true)

        nameField.current.value = ""
        emailField.current.value = ""
        phoneField.current.value = ""
        descriptionField.current.value = ""
        consentField.current.value = false
      })
      .catch(error => {
        setShowError(true)
      })
      .finally(() => {
        //Perform action in always
      })
  }

  useEffect(() => {
    if (isBrowser()) {
      const sections = document.querySelectorAll(
        "#gatsby-focus-wrapper section"
      )
      let currentSection = 0

      window.addEventListener("keyup", initScroller, true)

      function initScroller(event) {
        event.preventDefault()
        event.stopPropagation()

        if (event.code === "ArrowDown") {
          if (currentSection < sections.length - 1)
            currentSection = currentSection + 1

          scrollToSection(sections, currentSection)
        }
        if (event.code === "ArrowUp") {
          if (currentSection > 0) currentSection = currentSection - 1

          scrollToSection(sections, currentSection)
        }
      }

      return function cleanup() {
        window.removeEventListener("keyup", initScroller, true)
      }
    }
  })

  return (
    <>
      <Header
        locale={props.data.strapiContact.locale}
        siteTitle={props.data.site.siteMetadata?.title || `Title`}
        data={props.data.allStrapiHeader.nodes.find(
          header => header.locale === props.pageContext.locale
        )}
        localizations={
          props.data.strapiContact.localizations.data[0].attributes
        }
        contactSlug={props.data.strapiContact.slug}
      />
      <Layout>
        <Seo
          lang={props.data.strapiContact.locale}
          description={props.data.strapiContact.Description}
          title={props.data.strapiContact.Title}
        />
        <section className="pt-24 pb-1 lg:pt-48 lg:pb-16">
          <div className="flex flex-col justify-center px-6">
            <div className="text-center mb-8 lg:mb-12">
              <h1 className="l-heading block text-transparent bg-clip-text bg-gradient-to-r from-maroon to-orange-red">
                {props.data?.strapiContact?.Title}
              </h1>
              <p className="intro-text small max-w-intro mx-auto my-4">
                {props.data?.strapiContact?.Description}
              </p>
            </div>
          </div>
        </section>
        <section className="contact-us-bg">
          <div className="contact-us-container max-w-project flex flex-col lg:flex-row">
            <div className="contact-form-box font-headline">
              <h2 className="mb-16">{props.data?.strapiContact?.FormTitle}</h2>
              <div className="grid grid-cols-1 gap-6">
                <form
                  action="https://getform.io/f/f3b2e0b3-cd3f-4c67-861f-b8760df36abf"
                  method="POST"
                  onSubmit={event => handleSubmit(event)}
                >
                  <label className="block mb-16">
                    <input
                      type="text"
                      name="Name"
                      className="mt-0 block w-full px-3 border-0 border-b-2 border-maroon focus:ring-0 focus:border-maroon"
                      placeholder=" "
                      ref={nameField}
                    />
                    <span className="placeholder">
                      {props.data?.strapiContact?.FormName}
                    </span>
                  </label>

                  <label className="block mb-16">
                    <input
                      type="email"
                      name="Email"
                      className="mt-0 block w-full px-3 border-0 border-b-2 border-maroon focus:ring-0 focus:border-maroon"
                      placeholder=" "
                      ref={emailField}
                      required
                    />
                    <span className="placeholder">
                      {props.data?.strapiContact?.FormEmail}
                    </span>
                  </label>

                  <label className="block mb-16">
                    <input
                      type="tel"
                      name="Phone number"
                      className="mt-0 block w-full px-3 border-0 border-b-2 border-maroon focus:ring-0 focus:border-maroon"
                      placeholder=" "
                      ref={phoneField}
                    />
                    <span className="placeholder">
                      {props.data?.strapiContact?.FormPhoneNumber}
                    </span>
                  </label>

                  <label className="block mb-16">
                    <textarea
                      name="Description"
                      className="mt-0 block w-full px-3 border-0 border-b-2 border-maroon focus:ring-0 focus:border-maroon"
                      rows="2"
                      placeholder=" "
                      ref={descriptionField}
                    ></textarea>
                    <span className="placeholder">
                      {props.data?.strapiContact?.FormAbout}
                    </span>
                  </label>

                  <div className="block mb-16">
                    <div className="mt-2">
                      <div>
                        <label className="inline-flex">
                          <input
                            type="checkbox"
                            name="Consent"
                            className="text-maroon"
                            value="Yes"
                            required
                            ref={consentField}
                          />
                          <span className="ml-2 consent-text">
                            {props.data?.strapiContact?.FormConsent}
                          </span>
                          <input type="hidden" value="No" />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-footer">
                    <button
                      type="submit"
                      className="inline-block font-headline font-extrabold text-base lg:text-xl uppercase no-underline rounded-full bg-gradient-to-r from-maroon to-orange-red text-white lg:m-0 py-4 px-16 hover:from-shiraz hover:to-shiraz"
                    >
                      {props.data?.strapiContact?.FormSubmit}
                    </button>
                    {showError && (
                      <p className="error">
                        {props.data?.strapiContact?.ErrorMessage}
                      </p>
                    )}
                    {formSent && (
                      <p className="success">
                        {props.data?.strapiContact?.SuccessMessage}
                      </p>
                    )}
                  </div>
                </form>
              </div>
            </div>
            <div className="address-box">
              <h2>{props.data?.strapiContact?.CalendlyTitle}</h2>
              <div class="py-7 text-[18px]">
                <p>{props.data?.strapiContact?.CalendlyText}</p>
                <a
                  href={props.data?.strapiContact?.CalendlyLink}
                  target="_blank"
                  class="inline-block font-headline font-extrabold text-base lg:text-xl uppercase no-underline rounded-full btn-bg-animated bg-gradient-to-r from-maroon to-orange-red text-white lg:m-0 py-4 px-16 hover:from-shiraz hover:to-shiraz"
                >
                  {props.data?.strapiContact?.CalendlyButtonLabel}
                </a>
              </div>
              <h2>{props.data?.strapiContact?.LocationTitle}</h2>
              <div className="py-7 text-[18px]">
                <ReactMarkdown>
                  {
                    props.data?.strapiContact?.LocationContent.data
                      ?.LocationContent
                  }
                </ReactMarkdown>
              </div>
              <h2 className="py-7">
                {props.data.strapiGeneralSetting.SocialLinks.Heading}
              </h2>
              <Social links={props.data.strapiGeneralSetting.SocialLinks} />
            </div>
          </div>
        </section>
      </Layout>
      <Footer
        contactSlug={props.data.strapiContact.slug}
        locale={props.data.strapiContact.locale}
        socialLinks={props.data.strapiGeneralSetting.SocialLinks}
        data={props.data.allStrapiFooter.nodes.find(
          header => header.locale === props.pageContext.locale
        )}
      />
    </>
  )
}

export const query = graphql`
  query ($locale: String) {
    site {
      siteMetadata {
        title
      }
    }

    strapiContact(locale: { eq: $locale }) {
      Description
      FormAbout
      FormConsent
      FormEmail
      FormName
      FormSubmit
      FormPhoneNumber
      FormTitle
      ErrorMessage
      SuccessMessage
      CalendlyTitle
      CalendlyText
      CalendlyLink
      CalendlyButtonLabel
      LocationTitle
      SocialTitle
      LocationContent {
        data {
          LocationContent
        }
      }
      Title
      slug
      locale
      localizations {
        data {
          attributes {
            locale
            slug
          }
        }
      }
    }

    allStrapiFooter {
      nodes {
        locale
        ContactText
        Heading
        RightsClaim
        SocialHeading
        Text
        Secondary {
          Link
          Text
        }
        Location {
          Heading
          Text {
            data {
              Text
            }
          }
        }
        Footer {
          Link
          Text
        }
      }
    }

    allStrapiHeader {
      nodes {
        locale
        MobileContactButtonText
        MobileContactHeading
        MobileContactText
        Links {
          Link
          Text
        }
        ContactText
      }
    }

    strapiGeneralSetting(locale: { eq: $locale }) {
      ContactSection {
        Headline
        TextPlainHTML {
          data {
            TextPlainHTML
          }
        }
      }

      SocialLinks {
        Heading
        Facebook
        LinkedIn
        Twitter
      }

      SocialShareLinks {
        Facebook
        LinkedIn
        Twitter
      }
    }

    strapiGeneralTranslation(locale: { eq: $locale }) {
      AllRightsReserved
      BackToBlog
      By
      Client
      CopiedToClipboard
      Keys
      ReadMore
      ScrollDownOrJustUse
      ViewProject
    }
  }
`

export default ContactPage
