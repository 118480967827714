function scrollToSection(sections, currentSection) {
  window.scrollTo({
    behavior: "smooth",
    top:
      window.scrollY +
      sections[currentSection].getBoundingClientRect().top -
      80,
  })
}

module.exports = {
  scrollToSection,
}
